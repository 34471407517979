export var colors = {
  black: '#000000',
  brand: {
    green: {
      active: '#48FFCF',
      accent: '#97FFE0',
      hover: '#23575A',
      // TODO: https://linear.app/republic/issue/RCE-1212/add-new-style-colors
      // Fix this color:
      darken: '#004530'
    },
    gray: {
      '50': '#505050',
      '70': '#707070 '
    }
  }
};