import _defineProperty from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

// https://chakra-ui.com/docs/styled-system/theming/component-style
var borderCss = {
  pos: 'absolute',
  top: '-2.5px',
  content: '" "',
  width: '54px',
  height: '54px',
  borderColor: 'brand.green.active',
  borderStyle: 'solid'
}; // https://github.com/chakra-ui/chakra-ui/issues/4581#issuecomment-900011232

export var Modal = {
  variants: {
    contactForm: {
      overlay: {
        bg: 'blackAlpha.300',
        backdropFilter: 'blur(5px)'
      },
      dialogContainer: {
        alignItems: 'center',
        p: 0
      },
      dialog: {
        maxW: '800px',
        px: {
          base: '1rem',
          md: '6rem'
        },
        py: {
          base: '1rem',
          md: '3rem'
        },
        my: 0,
        bg: 'blackAlpha.700',
        borderRadius: '0',
        // TopLeft and TopRight border render
        _before: _objectSpread(_objectSpread({}, borderCss), {}, {
          left: '-2.5px',
          borderWidth: '5px 0 0 5px'
        }),
        _after: _objectSpread(_objectSpread({}, borderCss), {}, {
          right: '-2.5px',
          borderWidth: '5px 5px 0 0'
        })
      },
      header: {
        fontSize: '40px',
        fontWeight: 400,
        color: 'white'
      },
      closeButton: {
        top: 8,
        insetEnd: 8,
        color: 'brand.green.hover',
        fontSize: '20px',
        zIndex: 2,
        _focus: {
          boxShadow: 'none'
        },
        _hover: {
          color: 'brand.green.active'
        }
      },
      footer: {
        justifyContent: 'flex-start'
      }
    }
  },
  defaultProps: {}
};