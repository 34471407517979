// https://chakra-ui.com/docs/styled-system/theming/component-style
export var Container = {
  baseStyle: {
    padding: {
      base: '2em',
      sm: '3em 4em',
      lg: '3em 1em'
    },
    maxWidth: '1170px'
  }
};