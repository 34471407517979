import { toAbsoluteURL } from '@r-client/shared/util/core';
import { NextSEOMetaTags } from '@r-client/shared/util/seo';

const TITLE = 'Republic Crypto // Accelerating the growth of web3';

const DESCRIPTION =
  "Republic Crypto offers web3 advisory services, blockchain infrastructure, and digital asset management to the world's most ambitious builders and investors.";
const getImageUrl = (urlOrigin: string) =>
  toAbsoluteURL(urlOrigin, '/_next-public/republic-crypto-preview.jpg');

export const SEOMetaTags = ({
  applicationURLOrigin,
}: {
  applicationURLOrigin: string;
}) => {
  const imageUrl = getImageUrl(applicationURLOrigin);
  return (
    <NextSEOMetaTags
      title={TITLE}
      metaTitle={TITLE}
      description={DESCRIPTION}
      image={imageUrl}
      openGraph={{
        title: TITLE,
        type: 'website',
        image: imageUrl,
        description: DESCRIPTION,
        url: applicationURLOrigin,
        siteName: TITLE,
      }}
      social={{
        twitter: {
          card: 'summary_large_image',
          site: 'republiccrypto',
          creator: 'republiccrypto',
          title: TITLE,
          description: DESCRIPTION,
          image: imageUrl,
          imageAlt: 'republic crypto',
        },
      }}
    />
  );
};
