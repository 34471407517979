// https://chakra-ui.com/docs/styled-system/theming/component-style
// You can also use the more specific type for
// a single part component: ComponentSingleStyleConfig
export var Button = {
  // The styles all button have in common
  baseStyle: {
    fontFamily: 'Inter',
    fontWeight: '700',
    textTransform: 'uppercase',
    borderRadius: 'base',
    // <-- border radius is same for all variants and sizes
    _focus: {
      boxShadow: 'none'
    }
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: '12',
      px: 4,
      // <-- px is short for paddingLeft and paddingRight
      py: 3 // <-- py is short for paddingTop and paddingBottom

    },
    md: {
      fontSize: '12',
      letterSpacing: '0.2em',
      px: 6,
      // <-- these values are tokens from the design system
      py: 3,
      // <-- these values are tokens from the design system
      h: '35px' // <-- modified to match Figma base button height

    }
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: '1px solid',
      borderRadius: '99em',
      borderColor: 'brand.green.active',
      color: 'white',
      _hover: {
        backgroundColor: 'brand.green.active',
        color: 'black'
      },
      _active: {
        backgroundColor: 'brand.green.active',
        color: 'black'
      }
    },
    solid: {
      bg: 'purple.500',
      color: 'white',
      border: 'px solid',
      _hover: {
        backgroundColor: 'purple.900'
      }
    },
    solidTransparent: {
      bg: 'white'
    }
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'outline'
  }
};