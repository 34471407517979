// https://chakra-ui.com/docs/styled-system/theming/component-style
export var borderCss = {
  pos: 'absolute',
  top: '-2.5px',
  content: '" "',
  width: '54px',
  height: '54px',
  borderColor: 'brand.green.active',
  borderStyle: 'solid'
}; // https://github.com/chakra-ui/chakra-ui/issues/4581#issuecomment-900011232

export var Input = {
  variants: {
    contactForm: {
      field: {
        fontSize: '14px',
        color: 'white',
        fontFamily: 'Roboto',
        pb: '10px',
        borderBottom: '1px solid',
        borderColor: 'brand.gray.70',
        borderRadius: 0,
        px: 0,
        bg: 'transparent',
        _placeholder: {
          color: 'brand.gray.70'
        },
        _readOnly: {
          boxShadow: 'none',
          userSelect: 'all'
        },
        _invalid: {
          borderColor: 'brand.gray.70',
          boxShadow: 'none'
        },
        _focus: {
          borderColor: 'brand.green.active',
          boxShadow: 'none'
        }
      }
    }
  },
  defaultProps: {
    size: 'sm'
  }
};