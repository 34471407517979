// https://chakra-ui.com/docs/styled-system/theming/component-style
export var Divider = {
  baseStyle: {
    borderColor: 'brand.gray.70',
    borderStyle: 'solid',
    mt: 1,
    mb: 1
  },
  variants: {
    "default": {},
    pageSection: {
      mb: {
        base: 8,
        md: 12
      }
    }
  },
  // The default variant value
  defaultProps: {
    variant: 'default'
  }
};