// https://chakra-ui.com/docs/styled-system/theming/component-style
export var Link = {
  baseStyle: {
    fontFamily: 'Inter',
    color: 'inherit',
    _focus: {
      boxShadow: 'none'
    },
    _hover: {
      color: 'brand.green.active'
    }
  },
  variants: {
    "default": {
      fontFamily: 'Inter',
      fontWeight: '700',
      letterSpacing: '0.2em',
      fontSize: '12'
    },
    headerNav: {
      fontFamily: 'Inter',
      fontWeight: '700',
      letterSpacing: '0.2em',
      fontSize: '12',
      color: 'white',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'none'
      }
    },
    dropdownNav: {
      fontFamily: 'Inter',
      fontWeight: '500',
      letterSpacing: '0.2em',
      fontSize: '10',
      color: 'white',
      textTransform: 'uppercase',
      _hover: {
        textDecoration: 'none'
      }
    },
    footerSocial: {
      color: 'white',
      fontSize: '3xl',
      textDecoration: 'underline',
      textDecorationThickness: '2px !important',
      textUnderlineOffset: '5px'
    },
    footerNav: {
      fontSize: '13px',
      color: 'white'
    },
    inline: {
      fontSize: '14px',
      fontFamily: 'Republic-sans Light',
      color: 'brand.green.active'
    }
  },
  // The default variant value
  defaultProps: {
    variant: 'default'
  }
};