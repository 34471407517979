export var fontSizes = {
  '8': '8px',
  '10': '10px',
  '11': '11px',
  '12': '12px',
  '13': '13px',
  '14': '14px',
  '16': '16px',
  '25': '25px',
  '30': '30px',
  '40': '40px'
};