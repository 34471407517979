/* eslint-disable filename-rules/match */
import '../global.css';

import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { Fonts, theme } from '@r-client/republic-crypto/feature/home';
import { ContactModalProvider } from '@r-client/republic-crypto/feature/home';
import { GqlClientProvider } from '@r-client/shared/data/client';
import {
  createReportingConfig,
  ErrorReportingProvider,
  NavigationContextHandler,
  useReporting,
} from '@r-client/shared/data/error-reporting';
import { ConfigProvider } from '@r-client/shared/util/core';

import { getPublicConfig } from '../application.config';
import { SEOMetaTags } from '../components/seo-meta-tags/seo-meta-tags';
import { useGqlManager } from '../gql-client';

const publicConfig = getPublicConfig();
// Rollbar configuration
const _reportingConfig = createReportingConfig({
  accessToken: publicConfig.rollbarClientToken,
  codeVersion: publicConfig.applicationVersion,
  environment: publicConfig.applicationEnvironment,
  serverHost: publicConfig.graphqlEndpointUrl,
  kind: 'rollbar',
});

function CustomApp({ Component, pageProps }: AppProps) {
  const gqlClient = useGqlManager(pageProps);
  const reporting = useReporting();
  const router = useRouter();
  return (
    <>
      <SEOMetaTags applicationURLOrigin={publicConfig.applicationURLOrigin} />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K3QPMQK');
      `}
      </Script>
      <GqlClientProvider client={gqlClient} reporting={reporting}>
        <NavigationContextHandler useRouter={useRouter} />
        <ConfigProvider value={publicConfig}>
          <ChakraProvider theme={theme}>
            <ContactModalProvider>
              <Fonts basePath={router.basePath} />
              {/* https://dev.to/jameswallis/animating-next-js-page-transitions-with-framer-motion-1g9j */}
              <AnimatePresence initial={true}>
                <Component {...pageProps} />
              </AnimatePresence>
            </ContactModalProvider>
          </ChakraProvider>
        </ConfigProvider>
      </GqlClientProvider>
    </>
  );
}

function AppWithErrorReporting(props: AppProps) {
  return (
    <ErrorReportingProvider config={_reportingConfig}>
      <CustomApp {...props} />
    </ErrorReportingProvider>
  );
}

export default AppWithErrorReporting;
