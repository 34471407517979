// https://chakra-ui.com/docs/styled-system/theming/customize-theme
import { extendTheme } from '@chakra-ui/react';
import { Accordion } from './components/accordion';
import { Button } from './components/button';
import { Container } from './components/container';
import { Divider } from './components/divider';
import { Input } from './components/input';
import { Link } from './components/link';
import { Modal } from './components/modal';
import { Tag } from './components/tag';
import { Text } from './components/text';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { fontSizes } from './foundations/font-sizes';
import { sizes } from './foundations/sizes';
import { styles } from './styles';
export var theme = extendTheme({
  colors: colors,
  sizes: sizes,
  styles: styles,
  breakpoints: breakpoints,
  fontSizes: fontSizes,
  components: {
    Accordion: Accordion,
    Button: Button,
    Container: Container,
    Divider: Divider,
    Input: Input,
    Link: Link,
    Modal: Modal,
    Text: Text,
    Tag: Tag
  }
});