// https://chakra-ui.com/docs/styled-system/theming/component-style
// https://github.com/chakra-ui/chakra-ui/issues/4581#issuecomment-900011232
export var Tag = {
  baseStyle: {
    container: {
      paddingX: '14px',
      paddingY: '8px'
    }
  },
  sizes: {
    small: {
      container: {
        paddingY: '8px',
        paddingX: '14px',
        fontSize: '10px'
      },
      label: {}
    }
  },
  variants: {
    outline: {
      container: {
        border: '1px solid #FFFFFF',
        borderRadius: 'full',
        color: 'white',
        boxShadow: 'none'
      }
    }
  },
  defaultProps: {}
};