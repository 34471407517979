// https://chakra-ui.com/docs/styled-system/theming/component-style
export var Text = {
  baseStyle: {
    fontFamily: 'Republic-sans Light',
    color: 'white'
  },
  sizes: {},
  variants: {
    h2: {
      fontFamily: 'Republic-sans Light',
      fontWeight: 'light',
      fontSize: {
        base: '30',
        md: '40'
      },
      letterSpacing: '-0.03em'
    },
    h3: {
      fontFamily: 'Republic-sans Light',
      fontWeight: 'light',
      fontSize: {
        base: '25',
        md: '30'
      },
      lineHeight: '120%',
      letterSpacing: '-0.03em'
    },
    bodyBig: {
      fontFamily: 'Republic-sans Light',
      fontWeight: 400,
      fontSize: '16',
      lineHeight: '120%'
    },
    body: {
      fontFamily: 'Republic-sans Light',
      fontWeight: 400,
      fontSize: '14',
      lineHeight: '150%'
    },
    disclaimer: {
      fontFamily: 'Republic-sans Light',
      fontWeight: 400,
      fontSize: '11',
      lineHeight: '150%'
    },
    label: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12',
      lineHeight: 'normal'
    },
    mono: {
      fontFamily: 'Roboto'
    },
    uppercase: {
      textTransform: 'uppercase'
    },
    uppercaseAccent: {
      fontFamily: 'Roboto',
      textTransform: 'uppercase',
      color: 'brand.green.active',
      lineHeight: '1.3em',
      fontSize: '10'
    },
    footerTitle: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontWeight: 700,
      mb: '1.5rem',
      textTransform: 'uppercase',
      color: 'white'
    },
    footerSocial: {
      fontFamily: 'Republic-sans Light',
      fontSize: '3xl',
      fontWeight: 400,
      color: 'white',
      textDecoration: 'underline',
      textDecorationThickness: '2px !important',
      textUnderlineOffset: '5px',
      lineHeight: 1.5,
      letterSpacing: '0px'
    },
    footerSocialMobile: {
      fontFamily: 'Republic-sans Light',
      fontSize: '3xl',
      fontWeight: 400,
      color: 'white',
      textDecoration: 'underline',
      textDecorationThickness: '2px !important',
      letterSpacing: '0px',
      textUnderlineOffset: '5px',
      lineHeight: 1.6
    },
    underline: {
      fontFamily: 'Republic-sans Light',
      fontSize: '14',
      fontWeight: 400,
      color: 'white',
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
      display: 'inline'
    },
    bold: {
      fontFamily: 'Republic-sans Light',
      fontSize: '14',
      fontWeight: 700,
      color: 'white',
      display: 'inline'
    }
  },
  // The default size and variant values
  defaultProps: {
    variant: 'body'
  }
};